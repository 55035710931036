import React, { useEffect, useState } from 'react';
const axios = require('axios');
import * as urlList from '../../config';
import { useHistory, useLocation } from 'react-router-dom';
import SearchSection from './SearchSection';
import { useDispatch } from 'react-redux';
import * as actions from '@redux-actions/';
import { getHeaders, isMandatory } from '@utility/Api';
import { queryParams, SORTBY } from '@utility/const';
import { styledLogUtagView, USER_URLS, UtagViewPages } from '@components/link-utils';
import useSearch from '@hooks/useSearch';
import Layout from '@components/Layout';
import SearchTabs, { searchTabValues } from './SearchTabs';
import { sanitizePillsFiltersItems, selectIsUserEnabledForTrainingPills } from '@utility/TrainingPillsUtility';
import { DataLayerTracking, selectTrackingDataLayerBase } from '@model/TrackingClass';
import { useSelector } from '@hooks/redux';
import FiltersV3 from '@components/FiltersV3';
import FiltersProduct from '@components/FiltersProduct';
import {
  INITIAL_FILTER_NOVELTIES_SEARCH,
  INITIAL_FILTER_TP_SEARCH,
  ResponseGetProducts,
} from '@model/ProductClass';
import queryString from 'query-string';
import { HpBean } from '@model/HPBean';
import NoSearchResults from '@components/NoSearchResults';
import { useIsEnabledForCollections } from '@hooks/collections/useIsEnabledForCollections';
import SearchMenu from '@components/SearchMenu';
import useKeyboardStatus from '@hooks/useKeyboardStatus';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { useQuery } from '@hooks/useQuery';
import { triggerPageView } from '@utility/analytics-utils';
import { BrandsList } from '@model/Brand';
import { LevelEnrichedMap } from '@model/CoursesClass';

type Props = {};

const Search = ({ }: Props) => {
  const [searchVal, setSearchVal] = useState<string>('');
  const [timeoutSearch, setTimeoutSearch] = useState<any>(null);
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const lang = useSelector(state => state.utils.lang);
  const timeOnTraining = useSelector(state => state.course.timeOnTraining);
  const isTimeOnTrainingLoaded = useSelector(state => state.course.isTimeOnTrainingLoaded);
  const isLoadingSearchCourses = useSelector(state => state.search.isLoadingSearchCourses);
  const isLoadingSearchTP = useSelector(state => state.search.isLoadingSearchTP);
  const isLoadingSearchNovelties = useSelector(state => state.search.isLoadingSearchNovelties);
  const userProfile = useSelector(state => state.user.userProfile);
  const isSearchSpell = useSelector(state => state.search.isSearchSpell);
  const previouslySearched: string[] = useSelector(state => state.search.previouslySearched);
  const hpBeans: HpBean[] = useSelector(state => state.cms.hpBeans);
  const languageLoadedHpBeans: string = useSelector(state => state.cms.languageLoadedHpBeans);
  const isLoadingHpBeans: boolean = useSelector(state => state.cms.isLoadingHpBeans);
  const isLoadedSearchCourses: boolean = useSelector(state => state.search.isLoadedSearchCourses);
  const isLoadedSearchTP: boolean = useSelector(state => state.search.isLoadedSearchTP);
  const isLoadedSearchNovelties: boolean = useSelector(
    state => state.search.isLoadedSearchNovelties
  );

  const isUserEnabledForTrainingPills: boolean = useSelector(selectIsUserEnabledForTrainingPills);
  const isEnabledForCollections: boolean = useIsEnabledForCollections();
  const labelL1Map : LevelEnrichedMap = useSelector(state => state.utils.labelL1Map);
  const brandsList: BrandsList = useSelector(state => state.utils.brandsList);

  const [
    currentTab,
    setCurrentTab,
    searchCourses,
    searchCourseIds,
    searchCoursesNum,
    searchTrainingPillsArray,
    searchTrainingPillsNum,
    searchNoveltiesArray,
    searchNoveltiesNum,
    suggestedPhraseTab,
    useSearchVal,
  ] = useSearch(true, false);
  const [isTabGreater, setIsTabGreater] = useState<boolean>(null);
  // const printSearchResult = () => {
  //     //lang.TOP_RESULTS_FOR = "Top results for '{SEARCH}' of {TOTALSEARCH} total";
  //     let toPrintSearchResult = lang.TOP_RESULTS_FOR.replace("{SEARCH}", isSearchSpell && suggestedPhraseTab ? suggestedPhraseTab : searchQuery);
  //     return toPrintSearchResult.replace("{TOTALSEARCH}", searchCoursesNum.toString());
  // }

  const searchQuery: string = useSelector(state => state.search.searchQuery);
  const suggestedPhrase: string = useSelector(state => state.search.suggestedPhrase);
  const suggestedPhraseTP: string = useSelector(state => state.search.suggestedPhraseTP);
  const suggestedPhraseNovelties: string = useSelector(
    state => state.search.suggestedPhraseNovelties
  );

  const [forceNoCourseSuggestion, setForceNoCourseSuggestion] = useState<boolean>(false);
  const [filtersTrainingPills, setFiltersTrainingPills] = useState<ResponseGetProducts>(
    cloneDeep(INITIAL_FILTER_TP_SEARCH)
  );
  const [filtersNovelties, setFiltersNovelties] = useState<ResponseGetProducts>(
    cloneDeep(INITIAL_FILTER_NOVELTIES_SEARCH)
  );
  const baseTealiumTracking = useSelector(selectTrackingDataLayerBase);

  const isLoadingCourses: boolean = useSelector(state => state.course.isLoadingCourses);

  const [urlParams, setUrlParams] = useState(null);

  useEffect(() => {
    return () => {
      //clean TP redux objects
      dispatch(actions.resetTrainingPillsCatalogue());
      //clean novelties redux objects
      dispatch(actions.resetNoveltiesCatalogue());
      //clean search text typed by the user
      dispatch(actions.resetSearchCourses());
    };
  }, []);

  const query = useQuery();
  let searchQueryByQueryParam = query.get(queryParams.SEARCH_QUERY);

  useEffect(() => {
    // used by search menu
    if (searchQueryByQueryParam) {
      handleChange(searchQueryByQueryParam);
    }
  }, [searchQueryByQueryParam]);

  useEffect(() => {
    //get HP beans ("you may be interested in" section)
    if (userProfile && languageLoadedHpBeans !== userProfile.preferredLang && !isLoadingHpBeans) {
      dispatch(actions.getHpBeans(userProfile.preferredLang));
    }
  }, [userProfile]);

  // useEffect(() => {
  //   const queryObj = queryString.parse(window.location.search);
  //   let queryObjSearchQuery = queryObj?.[queryParams.SEARCH_QUERY];
  //   let stateSearchQuery = location?.state?.searchQuery;

  //   if (!queryObjSearchQuery) {
  //     queryObjSearchQuery = "";
  //   }

  //   if (!queryObjSearchQuery && queryObjSearchQuery != searchVal && !stateSearchQuery) {
  //     console.log("queryObjSearchQuery", { queryObjSearchQuery, searchVal, stateSearchQuery })
  //     handleChange(queryObjSearchQuery);
  //   }

  // }, [location]);

  // useEffect(() => {
  //   let urlParams = queryString.parse(window.location.search);
  //   if (urlParams && urlParams[queryParams.SEARCH_QUERY]) {
  //     const paramSearchQuery = urlParams[queryParams.SEARCH_QUERY];
  //     setForceNoCourseSuggestion(false);

  //     //start search if specified in query params
  //     handleChange(paramSearchQuery);
  //   }
  // }, []);

  useEffect(() => {
    if (suggestedPhraseTP || suggestedPhraseNovelties || searchQuery) {
      if (isUserEnabledForTrainingPills) {
        let url = urlList.GET_FILTERS_TRAINING_PILLS_URL;
        url +=
          '?text=' + (forceNoCourseSuggestion ? searchQuery : suggestedPhraseTP || searchQuery);

        axios
          .post(url, filtersTrainingPills.items, { headers: getHeaders() })
          .then(response => {
            let data = response.data;
            //if BE doesn't return sortings --> add them by FE
            if (
              data?.items &&
              !(data.items.sortings?.length > 0) &&
              filtersTrainingPills?.items?.sortings
            ) {
              data.items.sortings = cloneDeep(filtersTrainingPills.items.sortings);
            }
            setFiltersTrainingPills(response.data);
            // setFilters(mergeFilters(filters.items, response.data, brandsList));
          })
          .catch(error => {
            console.log('error', error);
          });
      }

      if (isEnabledForCollections) {
        let url = urlList.GET_FILTERS_NOVELTIES_URL;
        url +=
          '?text=' +
          (forceNoCourseSuggestion
            ? searchQuery
            : suggestedPhraseTP || suggestedPhraseNovelties || searchQuery);

        axios
          .post(url, filtersNovelties.items, { headers: getHeaders() })
          .then(response => {
            let data = response.data;
            data.items = sanitizePillsFiltersItems(data.items);

            //if BE doesn't return sortings --> add them by FE
            if (
              data?.items &&
              !(data.items.sortings?.length > 0) &&
              filtersNovelties?.items?.sortings
            ) {
              data.items.sortings = cloneDeep(filtersNovelties.items.sortings);
            }
            setFiltersNovelties(data);
            // setFiltersNovelties(mergeFilters(filtersNovelties.items, response.data, brandsList));
          })
          .catch(error => {
            console.log('error', error);
          });
      }
    }
  }, [isUserEnabledForTrainingPills, searchQuery, suggestedPhraseTP, suggestedPhraseNovelties]);

  const [firstTimeHandleSearchQuery, setFirstTimeHandleSearchQuery] = useState(true);
  useEffect(() => {
    if (firstTimeHandleSearchQuery) {
      setFirstTimeHandleSearchQuery(false);
      return;
    }

    const params = new URLSearchParams();
    let urlParams = cloneDeep(queryString.parse(window.location.search)) || {};
    Object.keys(urlParams).forEach(paramKey => {
      //do not delete actual query params
      if (urlParams[paramKey]) {
        params.set(paramKey, urlParams[paramKey]);
      }
    });

    //add or update searchQuery param
    params.set(queryParams.SEARCH_QUERY, searchQuery);

    history.replace({
      search: params.toString(),
      state: location.state,
    });
  }, [searchQuery]);

  const handleChange = val => {
    setSearchVal(val);

    if (searchVal === val) {
      return;
    }

    //send the search request only if the user is not typing for 500ms
    if (timeoutSearch) {
      clearTimeout(timeoutSearch);
    }
    const newTimeoutSearch = setTimeout(() => {
      dispatch(
        actions.getSearchCourses(
          val,
          isUserEnabledForTrainingPills,
          isEnabledForCollections,
          true,
          true
        )
      );
    }, 500);
    setTimeoutSearch(newTimeoutSearch);
  };

  useEffect(() => {
    if (window.location.search === '') {
      setSearchVal('')
    }
  }, [window.location.search])

  useEffect(() => {
    if(window.location.search.includes(queryParams.COURSE_DETAIL)) {
      return
    }
    if (useSearchVal !== searchVal) {
      return;
    }

    const courses = [...searchCourses];
    let isSearchReady = false;
    let products: Pick<
      DataLayerTracking,
      'Products_Id_Array' | 'Products_ModelName_Array' | 'product_format' | 'Products_Category_Array' | 'Products_Mandatory_Array' | 'Products_Language_Array'
    > = {};

    const isAnalyticsInfoLoaded =
      typeof searchQuery === 'string' &&
      isLoadedSearchCourses;

    //courses/lessons analytics
    if (
      isAnalyticsInfoLoaded &&
      Array.isArray(searchCourses) &&
      !isLoadingSearchCourses && !isLoadingSearchTP && !isLoadingSearchNovelties &&
      currentTab === searchTabValues.courses
    ) {

      // LEON-7245 
      // PREVENT DUPLICATED VIEW PUSH
      if(courses.length === 0 && ((!isLoadingSearchTP && isLoadedSearchTP) || (!isLoadingSearchNovelties && isLoadedSearchNovelties))) {
        return 
      }
      
      let coursesWithL1DifferentInfos = courses.map(course => {
        if (course.ctype.includes("l1") && !course.courseFullName) {
          course.courseIdMaster = undefined,
          course.courseFullName = undefined,
          course.catalogTypes = [undefined]
        } else {
          course
        }
        return course
      })
      products = {
        Products_Id_Array: coursesWithL1DifferentInfos.map(course => course.courseIdMaster),
        Products_ModelName_Array: coursesWithL1DifferentInfos.map(course => {
          let name = course.courseFullName;
          return name?.toLowerCase();
        }),
        //product_format: coursesWithL1DifferentInfos.reduce((acc, course) => [...acc, ...course.ctype], []), // flat
        Products_Category_Array: coursesWithL1DifferentInfos?.reduce(
          (acc, course) => [...acc, ...(course.catalogTypes.map(catalogType => labelL1Map?.[catalogType]?.label?.toLowerCase()) || [])],
          []
        ), // flat
        //Products_Mandatory_Array: coursesWithL1DifferentInfos.map(course => (isMandatory(course) ? '1' : course.ctype.includes("l1")? undefined :'0')),
        Products_Language_Array: coursesWithL1DifferentInfos.map(course => course?.language)
      };
      isSearchReady = true;
    }

    //training pills analytics
    if (
      isAnalyticsInfoLoaded &&
      Array.isArray(searchTrainingPillsArray) &&
      isLoadedSearchTP &&
      currentTab === searchTabValues.trainingPills
    ) {
      products = {
        Products_Id_Array: searchTrainingPillsArray.map(product => {
          let model =  product.model;
          return model.toLowerCase()
        }),
        Products_ModelName_Array: searchTrainingPillsArray.map(product => !!product?.modelName? product.modelName?.toLowerCase() : undefined),
        // product_format: [],
        Products_Category_Array: searchTrainingPillsArray.map(product => brandsList.map[product.brandString]?.name?.toLowerCase()),
        //Products_Mandatory_Array: searchTrainingPillsArray.map(product => undefined),
        Products_Language_Array: searchTrainingPillsArray.map(product => undefined)
      };
      isSearchReady = true;
    }

    //novelties analytics
    if (
      isAnalyticsInfoLoaded &&
      Array.isArray(searchNoveltiesArray) &&
      isLoadedSearchNovelties &&
      currentTab === searchTabValues.novelties
    ) {
      products = {
        Products_Id_Array: searchNoveltiesArray.map(product => product.model?.toLowerCase()),
        Products_ModelName_Array: searchNoveltiesArray.map(product => !!product?.modelName? product.modelName?.toLowerCase() : undefined),
        // product_format: [],
        Products_Category_Array: searchNoveltiesArray.map(product => brandsList.map[product.brandString]?.name?.toLowerCase()),
        //Products_Mandatory_Array: searchNoveltiesArray.map(product => undefined),
        Products_Language_Array: searchNoveltiesArray.map(product => undefined)
      };
      isSearchReady = true;
    }

    if (isSearchReady) {
      let searchResults = '0';

      switch (currentTab) {
        case searchTabValues.courses:
          searchResults = courses.length.toString();
          break;
        case searchTabValues.trainingPills:
          searchResults = searchTrainingPillsNum.toString();
          break;
        case searchTabValues.novelties:
          searchResults = searchNoveltiesNum.toString();
          break;
      }
      const suggested = hpBeans?.some(bean => bean.label === searchVal);
      const utagViewData: DataLayerTracking = {
        ...baseTealiumTracking,
        ...UtagViewPages.SEARCH_RESULTS_MODAL,
        Search_ResultItemsQnt: searchResults,
        Search_Keyword: searchVal,
        Search_Type: suggested ? 'suggested' : 'text',
        ...products,
      };
      setTimeout(() => {
        styledLogUtagView('Search Modal Results', utagViewData);
        triggerPageView(utagViewData);
      }, 2000);
    }
  }, [
    userProfile,
    timeOnTraining,
    isTimeOnTrainingLoaded,
    //isLoadingSearchCourses,
    isLoadedSearchCourses,
    //searchCourses,
    //searchTrainingPillsArray,
    searchTrainingPillsNum,
    currentTab,
    useSearchVal,
    window.location.search
  ]);

  const forceSearch = (forceSearchQuery: string, forceNoCourseSuggestionParam: boolean) => {
    setForceNoCourseSuggestion(forceNoCourseSuggestionParam);
    dispatch(
      actions.getSearchCourses(
        forceSearchQuery,
        isUserEnabledForTrainingPills,
        isEnabledForCollections,
        true,
        true,
        false,
        forceNoCourseSuggestionParam
      )
    );
  };

  const hasSuggested = previouslySearched?.length > 0 && hpBeans?.length > 0;
  // <SearchSection
  //     handleChange={handleChange}
  //     searchVal={searchVal}
  //     goToSearchCatalogue={() => {/* do nothing */ }}
  // />

  const hasKeyboard = useKeyboardStatus();
  const breakpoint = useBreakpointBoolean();

  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [shouldFocusOnce, setShouldFocusOnce] = useState<boolean>(false);

  const handleFocus = () => {
    setIsSearchFocused(true);
  };

  const handleBlur = () => {
    setIsSearchFocused(false);
  };

  const handleClickBackButton = () => {
    if (location.state?.useSearchDoubleBack) {
      history.go(-2);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    if (
      breakpoint.isDesktopOrTabletLandscape &&
      !shouldFocusOnce &&
      !location.state?.notFocus &&
      !searchQueryByQueryParam
    ) {
      const input = document.getElementById('search-section-input');
      if (input) {
        input.focus();

        setTimeout(() => {
          if (document.activeElement !== input) {
            //if input is not in focus --> retry to focus
            input.focus();

            setTimeout(() => {
              if (document.activeElement !== input) {
                //if input is not in focus --> retry to focus
                input.focus();
              }
            }, 300);
          }
        }, 300);
        setShouldFocusOnce(true);
      } else {
        setTimeout(() => {
          //wait 1s to render the element
          document.getElementById('search-section-input')?.focus();
          setShouldFocusOnce(true);
        }, 1000);
      }
    }
    return () => {
      setShouldFocusOnce(false);
    };
  }, [breakpoint.isDesktopOrTabletLandscape]);

  const searchContent = (
    <>
      <SearchSection
        handleChange={handleChange}
        searchVal={searchVal}
        goToSearchCatalogue={() => {
          /* do nothing */
        }}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
      />
      <div
        className={
          'search-container__columns' +
          (isSearchFocused && hasKeyboard ? ' search-container__columns--focused' : '')
        }
      >
        <SearchMenu show={!(searchVal?.length >= 3)} notModal breakpoint={breakpoint} />
      </div>
      {/* <CustomScrollbarV3 class="search-modal-content__scroll"> */}
      <div className="search-modal-content">
        {
          searchVal?.length >= 3 && (
            <>
              <div
                className={`search__results ${hasSuggested && !searchVal ? 'has-suggested' : ''}`}
              >
                <div className="search__results__content">
                  {searchQuery &&
                    isLoadedSearchCourses &&
                    (!isSearchSpell || suggestedPhraseTab) && (
                      <div className="search__results__header">
                        <section className="results" aria-label={lang.RESULTS_ON_LABEL}>
                          <span className="search__results__title">
                            {isSearchSpell && suggestedPhraseTab &&
                              lang.SEARCH_RESULTS_FOR.replace('{SEARCH}', searchQuery).replace('{SUGGESTED}', suggestedPhraseTab)
                            }
                          </span>
                        </section>
                      </div>
                    )}
                  {!isLoadingCourses &&
                    !isLoadingSearchCourses &&
                    searchCourses?.length === 0 &&
                    searchTrainingPillsNum === 0 &&
                    searchNoveltiesNum === 0 &&
                    searchQuery ? (
                    <NoSearchResults
                      searchQuery={searchQuery}
                      suggestedPhrase={suggestedPhraseTab}
                      search={forceSearch}
                    />
                  ) : (
                    <div className="search__results__tabs-wrapper">
                      {isLoadedSearchCourses && (
                        <SearchTabs
                          lang={lang}
                          currentTab={currentTab}
                          setCurrentTab={setCurrentTab}
                          searchCourses={searchCourses}
                          searchTrainingPillsNum={searchTrainingPillsNum}
                          searchNoveltiesNum={searchNoveltiesNum}
                          setIsTabGreater={setIsTabGreater}
                        />
                      )}
                      <div className="search__tabs__content">
                        {currentTab === searchTabValues.courses ? (
                          <FiltersV3
                            categoryName=""
                            selectedChannel={'all'}
                            category={'search'}
                            isFromCarousel={false}
                            searchType={suggestedPhrase ? 'suggested' : 'text'}
                            filteredCourses={searchCourses}
                            areLPCourses={false}
                            defaultSortBy={SORTBY.RELEVANCE_FAKE}
                            urlParamsSearch={urlParams}
                            cardVariant="search"
                            rightAnimation={isTabGreater}
                          />
                        ) : (
                          <FiltersProduct
                            key={location.pathname + currentTab}
                            categoryName={'search'}
                            category={'channel'}
                            isFromCarousel={false}
                            initialFilters={
                              currentTab === searchTabValues.novelties
                                ? filtersNovelties
                                : filtersTrainingPills
                            }
                            isSearch
                            searchQuery={
                              forceNoCourseSuggestion
                                ? searchQuery
                                : suggestedPhraseTP || suggestedPhraseNovelties || searchQuery
                            }
                            searchForceNoCourseSuggestion={forceNoCourseSuggestion}
                            urlParamsSearch={urlParams}
                            showTPAsMobile
                            isNovelties={currentTab === searchTabValues.novelties}
                            rightAnimation={isTabGreater}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )
          // :
          // <div className="search__suggestions">
          //     {previouslySearched?.length > 0 &&
          //         <section className="search__suggestions__box" aria-label={lang.SEARCH_PREVIOUSLY_SEARCHED}>
          //             <p className="search__suggestions__box__title">{lang.SEARCH_PREVIOUSLY_SEARCHED}</p>
          //             <div className="search__suggestions__box__content">
          //                 {previouslySearched.map(prev =>
          //                     <ButtonV2 key={prev} variant="text-btn-no-arrow" small handleClick={() => handleChange(prev)}>{prev}</ButtonV2>
          //                 )}
          //             </div>
          //         </section>
          //     }
          //     {hpBeans?.length > 0 &&
          //         <section className="search__suggestions__box" aria-label={lang.SEARCH_YOU_MIGHT_BE_INTERESTED_IN}>
          //             <p className="search__suggestions__box__title">{lang.SEARCH_YOU_MIGHT_BE_INTERESTED_IN}</p>
          //             <div className="search__suggestions__box__content">
          //                 {hpBeans.map((hpBean) =>
          //                     <Chip key={hpBean.key || uuidv4()} toggle={() => handleChange(hpBean.label)} label={hpBean.label} checked={false}></Chip>
          //                 )}
          //             </div>
          //         </section>
          //     }
          // </div>
        }
      </div>

      {/* </CustomScrollbarV3> */}
    </>
  );

  return (
    <Layout
      hideFooter
      layoutContainerClassname="search-catalogue search-page"
      headerProps={{
        isBackAbsolute: true,
        isModal: true,
        inSearchPage: true,
        useSquareButton: true,
      }}
      customBackAction={handleClickBackButton}
    >
      {searchContent}
    </Layout>
  );
};

export default Search;
